import Swal from 'sweetalert2';
import Constant from './Constant';
import LangManager from './LangManager';
import AuthController from './controller/AuthController';

export default class AuthenticationManager {

    static get TOKEN_KEY() {
        return "kids-lab.io-token";
    }

    static get USER_KEY() {
        return "kids-lab.io-user";
    }

    static get PLAN_KEY() {
        return "kids-lab.io-plan";
    }

    static get FUNCTIONALITIES_KEY() {
        return "kids-lab.io-functionalities";
    }

    static get EMAIL_KEY() {
        return "kids-lab.io-email";
    }

    static get USERID_KEY() {
        return "kids-lab.io-userId";
    }
    
    static get IS_REGISTER_KEY() {
        return "kids-lab.io-isRegistered";
    }

    static get userFunctionalities() {
        if (!this._userFunctionalities) {
            const funcArray = window.localStorage.getItem(AuthenticationManager.FUNCTIONALITIES_KEY) || '[]';
            this._userFunctionalities = new Set(JSON.parse(funcArray));
        }

        return this._userFunctionalities;

    }

    constructor() {
        this._langManager = new LangManager();
        this._onLoginRequired = null;
        this._userFunctionalities = null;
    }

    get isRegistered() {
        return localStorage.getItem(AuthenticationManager.IS_REGISTER_KEY) ? true : false;
    }

    set registered(value) {
        localStorage.setItem(AuthenticationManager.IS_REGISTER_KEY, value);
    }

    storeData(data) {
        window.localStorage.setItem(AuthenticationManager.USERID_KEY, data.userId || -1);
        window.localStorage.setItem(AuthenticationManager.PLAN_KEY, data.plan || Constant.DEFAULT_PLAN);

        this._userFunctionalities = new Set(data.functionalities);
        window.localStorage.setItem(AuthenticationManager.FUNCTIONALITIES_KEY, JSON.stringify(data.functionalities || '[]'));
    }

    refreshUser() {
        return new Promise(
            (resolve, reject) => {
                AuthController.me()
                    .then(data => {
                        this.storeData(data);
                        resolve(data);
                        this.registerd = true;
                    })
                    .catch(err => {
                        this.storeData(err.response.data);
                        resolve(err);
                    })
            }
        );
    }

    verifyUser() {
        return new Promise(
            (resolve, reject) => {
                AuthController.me()
                    .then(data => {
                        this.storeData(data);
                        resolve(data);
                        this.registerd = true;
                    })
                    .catch(err => {
                        this.storeData(err.response.data);
                        const regForm = window.localStorage.getItem(AuthenticationManager.USER_KEY) === null ? this.register.bind(this) : this.authenticate.bind(this);
                        regForm()
                            .then(resolve)
                            .catch(reject);
                    })
            }
        );
    }

    clear() {
        window.localStorage.removeItem(AuthenticationManager.TOKEN_KEY);
        this._userFunctionalities = null;
    }

    clearRegistered() {
        window.localStorage.removeItem(AuthenticationManager.IS_REGISTER_KEY);
    }

    authenticate() {
        return new Promise((resolve, reject) => {

            Swal.fire({
                title: this._langManager.getText('AUTH_LOGIN_TITLE'),
                html: `
                    <form id="swal-login-form" onSubmit="return false;">
                        <input id="swal-login" class="swal2-input" placeholder="${this._langManager.getText('AUTH_LOGIN_LOGIN')}" />
                        <input id="swal-password" class="swal2-input" type="password" placeholder="${this._langManager.getText('AUTH_LOGIN_PASSWORD')}" />
                        ${this._langManager.getText('AUTH_LOGIN_IF_NO_USER')}<a href="#" id="swal-register">${this._langManager.getText('AUTH_LOGIN_NEW')}</a><br><br>
                        <input name="doLogin" class="swal2-confirm swal2-styled"  type="submit" value="${this._langManager.getText('LOGIN')}" />
                        <button id="swal-cancel" type="button" class="swal2-cancel swal2-styled" style="display: inline-block;" aria-label="">${this._langManager.getText('CANCEL')}</button>
                    </form>
                        `,
                showConfirmButton: false,
                showCancelButton: false,
                showLoaderOnConfirm: true,
                onOpen: () => {
                    const user = window.localStorage.getItem(AuthenticationManager.USER_KEY) || "";
                    document.getElementById('swal-login').value = user;

                    const form = document.getElementById('swal-login-form');
                    form.addEventListener("submit", () => {
                        Swal.clickConfirm();
                    })

                    const register = document.getElementById('swal-register');
                    register.addEventListener('click', () => {
                        this.register()
                            .then(resolve)
                            .catch(reject);
                    })

                    const cancel = document.getElementById('swal-cancel');
                    cancel.addEventListener('click', () => {
                        Swal.clickCancel();
                    })
                },
                preConfirm: () => {
                    const login = document.getElementById('swal-login').value;
                    const password = document.getElementById('swal-password').value;

                    window.localStorage.setItem(AuthenticationManager.USER_KEY, login);

                    return AuthController.login(login, password)
                        .then(data => {
                            this.storeData(data);

                            this.registerd = true;
                            return data;
                        })
                        .catch(err => {
                            const message = err.response.status == 404 ? this._langManager.getText('AUTH_LOGIN_ERROR_USER_UNKNOWN') :
                                this._langManager.getText('AUTH_LOGIN_ERROR_PASSWORD_INCORRECT')
                            console.error(err);
                            Swal.showValidationMessage(message)
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (!result.dismiss) {
                    window.localStorage.setItem(AuthenticationManager.TOKEN_KEY, result.value.token);
                    resolve(result);
                } else {
                    reject(result);
                }
            }).catch(reject)
        });

    }

    register() {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: this._langManager.getText('AUTH_REGISTER_TITLE'),
                html: `
                    <form id="swal-login-form" onSubmit="return false;">
                        <input id="swal-login" class="swal2-input" placeholder="${this._langManager.getText('AUTH_LOGIN_USER')}" />
                        <input id="swal-email" class="swal2-input" placeholder="${this._langManager.getText('AUTH_LOGIN_EMAIL')}" />
                        <input id="swal-password" class="swal2-input" type="password" placeholder="${this._langManager.getText('AUTH_LOGIN_PASSWORD')}" />
                        <input id="swal-password2" class="swal2-input" type="password" placeholder="${this._langManager.getText('AUTH_LOGIN_RE_PASSWORD')}" />
                        ${this._langManager.getText('AUTH_LOGIN_IF_USER')}<a href="#" id="swal-register">${this._langManager.getText('LOGIN')}</a><br><br>
                        <input name="doLogin" class="swal2-confirm swal2-styled"  type="submit" value="${this._langManager.getText('REGISTER')}" />
                        <button id="swal-cancel" type="button" class="swal2-cancel swal2-styled" style="display: inline-block;" aria-label="">${this._langManager.getText('CANCEL')}</button>
                    </form>
                    `,
                showConfirmButton: false,
                showCancelButton: false,
                showLoaderOnConfirm: true,
                onOpen: () => {
                    const user = window.localStorage.getItem(AuthenticationManager.USER_KEY) || "";
                    document.getElementById('swal-login').value = user;
                    const email = window.localStorage.getItem(AuthenticationManager.EMAIL_KEY) || "";
                    document.getElementById('swal-email').value = email;

                    const form = document.getElementById('swal-login-form');
                    form.addEventListener("submit", () => {
                        Swal.clickConfirm();
                    })

                    const register = document.getElementById('swal-register');
                    register.addEventListener('click', () => {
                        this.authenticate()
                            .then(resolve)
                            .catch(reject);

                            this.registered = true;
                    })

                    const cancel = document.getElementById('swal-cancel');
                    cancel.addEventListener('click', () => {
                        Swal.clickCancel();
                    })
                },
                preConfirm: () => {
                    const user = document.getElementById('swal-login').value;
                    const email = document.getElementById('swal-email').value;
                    const password = document.getElementById('swal-password').value;
                    const password2 = document.getElementById('swal-password2').value;

                    if (password != password2 || password == "") {
                        Swal.showValidationMessage(this._langManager.getText('AUTH_LOGIN_ERROR_PASSWORD_INCORRECT'));
                        return;
                    };

                    window.localStorage.setItem(AuthenticationManager.USER_KEY, user);
                    window.localStorage.setItem(AuthenticationManager.EMAIL_KEY, email);

                    return AuthController.register(user, email, password)
                        .then(data => {
                            this.storeData(data);
                            return data;
                        })
                        .catch(err => {
                            console.error(err.response);
                            Swal.showValidationMessage(
                                `${this._langManager.getText('AUTH_LOGIN_ERROR_UNEXPECTEDT')} ${err.response.data}`
                            )
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (!result.dismiss) {
                    window.localStorage.setItem(AuthenticationManager.TOKEN_KEY, result.value.token);
                    resolve(result);
                } else {
                    reject(result);
                }
            }).catch(reject)
        });
    }

}