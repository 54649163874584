import TutorialController from '../controller/TutorialController';
import Constant from '../Constant';
import mergeImages from 'merge-images';
import LangManager from '../LangManager';
import VideoController from '../controller/VideoController';

export default class TutorialContent {

    static getImagePath(name) {
        return `./img/tutorials/${name}.png`
    }

    constructor(idElement) {
        this._element = document.getElementById(idElement);
        this._langManager = new LangManager();
    }

    render() {
        this.clear();

        const h1 = document.createElement('h1');
        h1.innerHTML = this._langManager.getText('TUTORIAL_TITLE');
        this._element.append(h1);

        const ul = document.createElement('ul');
        this._element.append(ul);

        VideoController.getAll(this._langManager.lang)
            .then(data => {
                for (const item of data) {
                    this.addVideo(ul, item);
                }
            });

        TutorialController.getAll()
            .then(data => {
                for (const item of data) {
                    this.addItem(ul, item);
                }
            })
    }

    addVideo(element, item) {

        const li = document.createElement('li');
        const div = document.createElement('div');
        div.classList.add('item');

        const a = document.createElement('a');
        div.appendChild(a);

        const img = document.createElement('img');

        if (item.image) {
            const imgs = [TutorialContent.getImagePath(item.image)];
            if (item.locked) imgs.push('./img/lock.png');

            mergeImages(imgs)
                .then(content => img.src = content);
        } else {
            img.src = `https://img.youtube.com/vi/${item.address}/0.jpg`;
            img.width = 204;
        }

        a.appendChild(img);
        a.appendChild(document.createElement('br'));
        const span = document.createElement('span');
        span.innerText = item.name;
        a.appendChild(span);

        li.appendChild(div);

        if (!item.locked) {
            a.addEventListener('click', (event) => {
                window.open(`http://www.youtube.com/embed/${item.address}`, item.name, "menubar=no, status=no, scrollbars=no, menubar=no, width=1200, height=675");
            }, false)
        }

        element.appendChild(li);

    }


    addItem(element, item) {

        const li = document.createElement('li');
        const div = document.createElement('div');
        div.classList.add('item');

        const a = document.createElement('a');
        div.appendChild(a);

        const img = document.createElement('img');

        const imgs = [TutorialContent.getImagePath(item.name)];
        if (item.locked) imgs.push('./img/lock.png');

        mergeImages(imgs)
            .then(content => img.src = content);


        a.appendChild(img);
        a.appendChild(document.createElement('br'));
        const span = document.createElement('span');
        span.innerText = item.name;
        a.appendChild(span);

        li.appendChild(div);

        a.addEventListener('click', (event) => this.example_onClick(event, item), false)

        element.appendChild(li);
    }

    example_onClick(event, item) {
        console.log(item);
        if (item.locked) {
            // TODO popup update your plan
        } else {
            window.location.href = `${Constant.LAB_URL}?lang=${this._langManager.lang}&tutorialId=${item.id}&part=1`;
        }
    }

    clear() {
        this._element.innerHTML = '';
    }

}