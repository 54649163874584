import axios from 'axios'
import BaseController from './BaseController';

export default class VideoController {
    
    static getAll(lang) {
        const query = `/api/videos/all/${lang}`;

        return new Promise((success, error) => {
            axios({
                    method: 'get',
                    url: query,
                    headers: BaseController.headers
                }).then(response => {
                    console.log('Videos successfully loaded');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err, query);
                    error(err);
                });
        });

    }

}