import ExampleController from './../controller/ExampleController';
import Constant from '../Constant';
import mergeImages from 'merge-images';
import LangManager from '../LangManager';

export default class ExampleContent {

    static getImagePath(name) {
        return `./img/examples/${name}.png`
    }

    constructor(idElement) {
        this._element = document.getElementById(idElement);
        this._langManager = new LangManager();
    }

    render() {
        this.clear();

        const hr = document.createElement('hr');
        this._element.append(hr);

        const h1 = document.createElement('h1');
        h1.innerHTML = this._langManager.getText('EXAMPLE_TITLE');
        this._element.append(h1);

        const ul = document.createElement('ul');
        this._element.append(ul);

        ExampleController.getAll()
            .then(data => {
                for (const item of data) {
                    this.addItem(ul, item);
                }
            })
    }

    addItem(element, item) {

        const li = document.createElement('li');
        const div = document.createElement('div');
        div.classList.add('item');

        const a = document.createElement('a');
        div.appendChild(a);

        const img = document.createElement('img');

        const imgs = [ExampleContent.getImagePath(item.name)];
        if (item.locked) imgs.push('./img/lock.png');

        mergeImages(imgs)
            .then(content => img.src = content);


        a.appendChild(img);
        a.appendChild(document.createElement('br'));
        const span = document.createElement('span');
        span.innerText = item.name;
        a.appendChild(span);

        li.appendChild(div);

        a.addEventListener('click', (event) => this.example_onClick(event, item), false)

        element.appendChild(li);
    }

    example_onClick(event, item) {
        if (item.locked) {
            // TODO popup update your plan
        } else {
            window.location.href = `${Constant.LAB_URL}?lang=${this._langManager.lang}&exampleId=${item.id}`;
        }
    }

    clear() {
        this._element.innerHTML = '';
    }

}