import Swal from 'sweetalert2';
import Rating from 'rating';
import RatingController from './controller/RatingController';

import ServerConstants from './../../nodejs-backend/ServerConstants'
import LangManager from './LangManager';

export default class RateManager {

    constructor() {
        this._langManager = new LangManager();

        this._rating = [
            this._langManager.getText('VERY_BAD'),
            this._langManager.getText('BAD'),
            this._langManager.getText('GOOD'),
            this._langManager.getText('VERY_GOOD'),
            this._langManager.getText('EXCELLENT')
        ];
    }

    render() {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: this._langManager.getText('RATE_TITLE'),
                html: `
                    <form id="swal-rate-form" onSubmit="return false;">
                        <span class="rating">
                            <span class="star">★</span>
                        </span>
                        <input id="swal-rating" class="swal2-input" placeholder="${this._langManager.getText('EXCELLENT')}" readonly />
                        <textarea id="swal-comment" rows="5" class="swal2-textarea" placeholder="${this._langManager.getText('RATE_COMMENT')}" maxlength="${ServerConstants.MAX_COMMENT_LENGTH}"></textarea>
                        <input class="swal2-confirm swal2-styled"  type="submit" value="${this._langManager.getText('SEND')}" />
                        <button id="swal-cancel" type="button" class="swal2-cancel swal2-styled" style="display: inline-block;" aria-label="">${this._langManager.getText('CANCEL')}</button>
                    </form>
                    `,
                showConfirmButton: false,
                showCancelButton: false,
                showLoaderOnConfirm: true,
                onOpen: () => {
                    const ratingText = document.getElementById('swal-rating');
                    const container = document.querySelector('.rating');
                    const star = document.querySelector('.star');
                    star.parentNode.removeChild(star);

                    const rating = new Rating([1, 2, 3, 4, 5], {
                        container: container,
                        star: star
                    });

                    const form = document.getElementById('swal-rate-form');
                    rating.on('rate', (weight) => {
                        ratingText.value = this._rating[weight - 1];
                        ratingText.dataset.rate = weight;
                    });

                    rating.on('select', (weight) => {
                        ratingText.value = this._rating[weight - 1];
                    });
                    rating.set(5);


                    form.addEventListener("submit", () => {
                        Swal.clickConfirm();
                    })

                    const cancel = document.getElementById('swal-cancel');
                    cancel.addEventListener('click', () => {
                        Swal.clickCancel();
                    })
                },
                preConfirm: () => {
                    const ratingText = document.getElementById('swal-rating');
                    const comment = document.getElementById('swal-comment');

                    console.log(ratingText.dataset.rate);
                    console.log(comment.value);

                    return RatingController.create(ratingText.dataset.rate, comment.value)
                        .then(data => {
                            return data;
                        })
                        .catch(err => {
                            console.error(err.response);
                            Swal.showValidationMessage(
                                `${this._langManager.getText('RATE_ERROR')}${err.response.data}`
                            )
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (!result.dismiss) {
                    resolve(result);
                } else {
                    reject(result);
                }
            }).catch(reject)
        });
    }

}