import axios from 'axios'
import BaseController from './BaseController';

export default class TutorialController {
    
    static getAll() {
        const query = `/api/tutorials/all`;

        return new Promise((success, error) => {
            axios({
                    method: 'get',
                    url: query,
                    headers: BaseController.headers
                }).then(response => {
                    console.log('Tutorials successfully loaded');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err, query);
                    error(err);
                });
        });

    }

}