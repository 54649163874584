import Swal from 'sweetalert2';
import ParameterManager from '../../js-commons/src/ParameterManager';

let singleton = null;

export default class LangManager {


    static get IS_CHOOSEN_KEY() {
        return "kids-lab.io-isLangChoosen";
    }

    constructor() {
        if (!singleton) {
            this._params = new ParameterManager();
            this._msg = {};
            this.applyLang();

            singleton = this;
        }

        return singleton;
    }

    get isChoosen() {
        return localStorage.getItem(LangManager.IS_CHOOSEN_KEY) ? true : false;
    }

    set choosen(value) {
        localStorage.setItem(LangManager.IS_CHOOSEN_KEY, value);
    }

    clearChoosen() {
        localStorage.removeItem(LangManager.IS_CHOOSEN_KEY);
    }

    applyLang() {
        this._lang = this._params.getParam('lang');
    }

    register(key, msg) {
        this._msg[key] = msg;
        return this;
    }

    registerAll(msgs) {
        Object.assign(this._msg, msgs);
    }

    getText(key) {
        return this._msg[key][this._lang];
    }

    get lang() {
        return this._lang;
    }

    langDialog(cancel = false) {
        return new Promise((resolve, reject) => {

            Swal.fire({
                title: this.getText('LANGUAGE_TITLE'),
                html: `
                    <svg id="lang-en-flag" width="84" height="84" class="flag" xmlns="http://www.w3.org/2000/svg" version="1.1">
                        <title>English</title>
                        <image x="0" y="0" width="84" height="84" xlink:href="./img/flag-en.svg"></image>
                    </svg>
                    <br />
                    <hr />
                    <br />
                    <svg id="lang-fr-flag" width="84" height="84" class="flag" xmlns="http://www.w3.org/2000/svg" version="1.1">
                        <title>Français</title>
                        <image x="0" y="0" width="84" height="84" xlink:href="./img/flag-fr.svg"></image>
                    </svg>
                    `,
                showConfirmButton: false,
                showCancelButton: cancel,
                cancelButtonText: this.getText('CANCEL'),
                onOpen: () => {
                    const langEn = document.getElementById('lang-en-flag');
                    langEn.addEventListener('click', () => {
                        resolve({
                            lang: 'en'
                        });
                        this.choosen = true;
                        Swal.clickConfirm();
                    })

                    const langFr = document.getElementById('lang-fr-flag');
                    langFr.addEventListener('click', () => {
                        resolve({
                            lang: 'fr'
                        });
                        this.choosen = true;
                        Swal.clickConfirm();
                    })
                },
                allowOutsideClick: () => cancel
            }).then((result) => {
                if (!result.dismiss) {} else {
                    reject(result);
                }
            }).catch(reject)
        });

    }
}