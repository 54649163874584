import ShareController from '../controller/ShareController';
import Constant from '../Constant';
import LangManager from '../LangManager';

export default class ShareContent {

    static getImagePath(name) {
        return `./img/tutorials/${name}.png`
    }

    constructor(idElement) {
        this._element = document.getElementById(idElement);
        this._langManager = new LangManager();
    }

    render() {
        this.clear();

        const hr = document.createElement('hr');
        this._element.append(hr);

        const h1 = document.createElement('h1');
        h1.innerHTML = this._langManager.getText('SHARE_TITLE');
        this._element.append(h1);

        const ul = document.createElement('ul');
        this._element.append(ul);

        ShareController.getAll()
            .then(data => {
                for (const item of data) {
                    this.addItem(ul, item);
                }
            })
    }

    addItem(element, item) {

        const li = document.createElement('li');
        const div = document.createElement('div');
        div.classList.add('item');

        const a = document.createElement('a');
        div.appendChild(a);

        const img = document.createElement('img');
        img.setAttribute('src', item.image);

        a.appendChild(img);
        a.appendChild(document.createElement('br'));
        const span = document.createElement('span');
        if (item.user != null) span.innerText = `${item.user} - ${item.name}`;
        else span.innerText = item.name;
        a.appendChild(span);

        li.appendChild(div);

        a.addEventListener('click', (event) => this.project_onClick(event, item), false)

        element.appendChild(li);
    }

    project_onClick(event, item) {
        window.location.href = `${Constant.LAB_URL}?lang=${this._langManager.lang}&shareId=${item.id}`;
    }

    clear() {
        this._element.innerHTML = '';
    }

}