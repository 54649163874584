import ProjectContent from './contentProvider/ProjectContent';
import AuthenticationManager from './AuthenticationManager';
import TutorialContent from './contentProvider/TutorialContent';
import ExampleContent from './contentProvider/ExampleContent';
import MenuContent from './contentProvider/MenuContent';
import ShareContent from './contentProvider/ShareContent';
import RateManager from './RateManager';
import LangManager from './LangManager';
import ParameterManager from '../../js-commons/src/ParameterManager';
import AboutManager from './AboutManager';

const params = new ParameterManager();

params.registerParam('lang', 'en');

require('./msg');

const auth = new AuthenticationManager();
const langManager = new LangManager();

const rate = new RateManager();
const menus = new MenuContent();
const about = new AboutManager();
const tutorials = new TutorialContent('tutorialsPlaceholder');
const projects = new ProjectContent('projectsPlaceholder');
const examples = new ExampleContent('examplesPlaceholder');
const share = new ShareContent('communityPlaceholder');

const title = document.getElementById('title');
title.innerText = langManager.getText('TITLE');
title.addEventListener('click', () => {
  about.render(false);
})

const logo = document.getElementById('logo');
logo.addEventListener('click', () => {
  about.render(false);
})

const renderPage = function () {
  tutorials.render();
  menus.render();
  projects.render();
  examples.render();
  share.render();
}

const applyLang = function (lang) {
  params.setParam('lang', lang);
  langManager.applyLang();
  title.innerText = langManager.getText('TITLE');
  renderPage();
}

const showAbout = function () {
  about.render()
    .then(() => {
      auth.register()
      .then(() => {
        renderPage();
      })
    })
}

auth.refreshUser()
  .then(data => {

    if (params.hasParam('logout')) {
      params.deleteParam('logout');
      auth.clear();

      params.applyLocation();
      return;
    }

    renderPage();

  })

const authMenuItem = document.getElementById('authMenuItem');
authMenuItem.addEventListener('click', () => {
  auth.clear();

  renderPage();

  auth.verifyUser()
    .then(data => {
      renderPage();
    });
});

const langMenuItem = document.getElementById('langMenuItem');
langMenuItem.addEventListener('click', () => {
  langManager.langDialog(true)
    .then(result => {
      applyLang(result.lang);
    });
});

// TODO : For test only
// langManager.clearChoosen();
// auth.clearRegistered();

if (!langManager.isChoosen) {
  langManager.langDialog(true)
    .then(result => {
      applyLang(result.lang);
      showAbout();
    });
} else if (!auth.isRegistered) {
  showAbout();
}

const rateMenuItem = document.getElementById('rateMenuItem');
rateMenuItem.addEventListener('click', () => {
  rate.render();
});