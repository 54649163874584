import axios from 'axios'
import BaseController from './BaseController';

export default class ProjectController {
    static getAllProjects(userId) {
        const query = `/api/projects/all/${userId}`;

        return new Promise((success, error) => {
            axios({
                    method: 'get',
                    url: query,
                    headers: BaseController.headers
                }).then(response => {
                    console.log('Projects successfully loaded');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

    static getAllStudentsProjects(userId) {
        const query = `/api/projects/allChildren/${userId}`;

        return new Promise((success, error) => {
            axios({
                    method: 'get',
                    url: query,
                    headers: BaseController.headers
                }).then(response => {
                    console.log('Students projects successfully loaded');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });
    }

    static updateProject(projectId, name = null) {
        const query = `/api/projects/${projectId}`;

        const data = {};
        if (name != null) data.name = name;

        return new Promise((success, error) => {
            axios({
                    method: 'put',
                    url: query,
                    headers: BaseController.headers,
                    data: data
                }).then(response => {
                    console.log('Project updated successfully');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err, query);
                    error(err);
                });
        });

    }

    static deleteProject(projectId) {
        const query = `/api/projects/${projectId}`;

        return new Promise((success, error) => {
            axios({
                    method: 'delete',
                    url: query,
                    headers: BaseController.headers
                }).then(response => {
                    console.log('Project deleted successfully');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

    static createProject(userId, name) {
        const query = `/api/projects/`;

        return new Promise((success, error) => {
            axios({
                    method: 'post',
                    url: query,
                    headers: BaseController.headers,
                    data: {
                        userId: userId,
                        name: name
                    }
                }).then(response => {
                    console.log(response.data);
                    console.log('Project created successfully');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

    static duplicateProject(projectId, name) {
        const query = `/api/projects/`;

        return new Promise((success, error) => {
            axios({
                    method: 'post',
                    url: query,
                    headers: BaseController.headers,
                    data: {
                        projectId: projectId,
                        name: name
                    }
                }).then(response => {
                    console.log(response.data);
                    console.log('Project duplicated successfully');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

    static download(projectId, name) {
        // done by axios to secure transaction (header token)
        // otherwise use:
        // window.open(`/api/projects/download/${projectId}`);
        axios({
                url: `/api/projects/download/${projectId}`,
                method: 'GET',
                headers: BaseController.headers,
                responseType: 'blob', // important
            }).then((response) => {
                //console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.xml`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                BaseController.logError(err);
                error(err);
            });
    }

}