export default class Constant {
    
    static get LAB_URL() {
        return window.location.href.includes('8081') ? 'http://localhost:8082/' : '/game-lab';
    }

    static get DEFAULT_PLAN() {
        return 'FREE';
    }

}