import AuthenticationManager from '../AuthenticationManager';

export default class BaseController {

    static logError(error) {
        const msg = `Error occured for request '${error.response.config.url} status ${error.response.status}: ${error.response.data.message || error.response.data}'`;
        console.error(msg);
    }

    static get accessToken() {
        return window.localStorage.getItem(AuthenticationManager.TOKEN_KEY);
    }

    static get headers() {
        return {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'x-access-token',
            'x-access-token': BaseController.accessToken
        };
    }

}