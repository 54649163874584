import LangManager from "./LangManager";

new LangManager().registerAll({
    TITLE: {
        en: 'GAME',
        fr: 'JEUX'
    },
    LOGIN: {
        en: 'Login',
        fr: 'Connect toi'
    },
    REGISTER: {
        en: 'Register',
        fr: "Crée l'utilisateur"
    },
    SEND: {
        en: 'Send',
        fr: 'Envoyer'
    },
    GOT_IT: {
        en: 'Got it',
        fr: "J'ai compris"
    },
    NEXT_TIME: {
        en: 'Next time',
        fr: "Une prochaine fois"
    },
    CANCEL: {
        en: 'Cancel',
        fr: 'Abandonner'
    },
    EXCELLENT: {
        en: 'Excellent',
        fr: 'Super bien'
    },
    VERY_GOOD: {
        en: 'Very good',
        fr: 'Vraiment bien'
    },
    GOOD : {
        en: 'Good',
        fr: 'Bien'
    },
    BAD: {
        en: 'Bad',
        fr: 'Mauvais'
    },
    VERY_BAD: {
        en: 'Very bad',
        fr: 'Vraiment mauvais'
    },
    AUTH_LOGIN_TITLE: {
        en: 'Login',
        fr: 'Se connecter'
    },
    AUTH_REGISTER_TITLE: {
        en: 'Register',
        fr: "S'enregistrer"
    },
    AUTH_LOGIN_LOGIN: {
        en: 'Enter username or email',
        fr: 'Entre ton nom ou ton mail'
    },
    AUTH_LOGIN_PASSWORD: {
        en: 'Enter your password',
        fr: 'Entre ton mot de passe'
    },
    AUTH_LOGIN_RE_PASSWORD: {
        en: 'Re-enter your password',
        fr: 'Entre à nouveau ton mot de passe'
    },
    AUTH_LOGIN_IF_NO_USER: {
        en: 'If you do not have account yet: ',
        fr: "Si tu n'a pas encore créé d'utilisateur : "
    },
    AUTH_LOGIN_IF_USER: {
        en: 'If you already have an account: ',
        fr: "Si tu a déjà créé un utilisateur : "
    },
    AUTH_LOGIN_NEW: {
        en: 'Register new user',
        fr: 'Créer un nouvel utilisateur'
    },
    AUTH_LOGIN_USER: {
        en: 'Enter your name',
        fr: 'Entre ton nom'
    },
    AUTH_LOGIN_EMAIL: {
        en: 'Enter your e-mail',
        fr: 'Entre ton e-mail'
    },
    AUTH_LOGIN_ERROR_USER_UNKNOWN: {
        en: 'User is unknown!',
        fr: "L'utilisateur est inconnu !"
    },
    AUTH_LOGIN_ERROR_PASSWORD_INCORRECT: {
        en: 'Password is incorrect!',
        fr: "Le mot de passe est incorrect !"
    },
    AUTH_LOGIN_ERROR_UNEXPECTEDT: {
        en: 'Registration failed: ',
        fr: "Erreur d'authentification : "
    },
    LANGUAGE_TITLE: {
        en: 'Language',
        fr: 'Langue'
    },
    TUTORIAL_TITLE: {
        en: 'Tutorials',
        fr: 'Didacticiels'
    },
    PROJECT_TITLE: {
        en: 'Projects',
        fr: 'Projets'
    },
    PROJECT_STUDENT_TITLE: {
        en: 'Students projects',
        fr: 'Projets étudiants'
    },
    RATE_TITLE: {
        en: 'Rate me',
        fr: 'Note moi'
    },
    RATE_COMMENT: {
        en: 'Enter here your comment',
        fr: 'Ecrit ici ton commentaire'
    },
    RATE_ERROR: {
        en: 'Rating failed: ',
        fr: "L'envoie de la note a échoué : "
    },
    ABOUT_TITLE: {
        en: 'About',
        fr: 'A propos'
    },
    ABOUT_TEXT: {
        en: `Hello and welcome to <a hred="www.kids-lab.io">kids-lab.io</a><br/><br/>
        <a hred="www.kids-lab.io">kids-lab.io</a> is a new programming language that gives you the ability to create your <b>own video game</b>.<br /> <br/>
        This web site is for you, kids that always dreamed to create your <b>own video game</b>!<br /><br/>
        It is also dedicated to <b>parent</b> that wish to teach <b>programming</b> to their children.<br /> <br/>
        Or to <b>teachers</b> that search after an <b>innovative</b>, <b>funny</b> and <b>educative</b> platform to teach programming concepts.<br /> <br/>`,
        fr: `Bonjour et bienvenu dans <a hred="www.kids-lab.io">kids-lab.io</a><br/><br/>
        <a hred="www.kids-lab.io">kids-lab.io</a> est un nouveau langage de programmation qui va te permettre de créer ton <b>propre jeu vidéo</b>.<br /> <br/>
        Ce site s'adresse d'abord enfants qui ont toujours rêvés de créer leur <b>jeu-vidéo</b> !<br /><br />
        Mais aussi à leurs <b>parents</b> qui souhaitent apprendre à leurs enfants les bases de la <b>programmation informatique</b>.<br /><br/>
        Ou encore aux <b>enseignants</b> qui cherchent une plate-forme <b>innovante</b> et <b>ludo-éducative</b> pour enseigner les concepts de la programmation.<br /><br />`
    },
    EXAMPLE_TITLE: {
        en: 'Examples',
        fr: 'Exemples'
    },
    SHARE_TITLE: {
        en: 'Community',
        fr: 'Communauté'
    }
});