import AuthenticationManager from "../AuthenticationManager";

export default class MenuContent {

    applyToMenu(elementId, functionality) {
        const element = document.getElementById(elementId);
        if (element) {
            const display = AuthenticationManager.userFunctionalities.has(functionality) ? 'inline-block' : 'none';
            element.style.display = display;
        }
    }

    render() {
        this.applyToMenu('authMenuItem', 'MENU_LOGIN');
        this.applyToMenu('extMenuItem', 'MENU_PLAN');
        this.applyToMenu('shareMenuItem', 'MENU_SHARE');
    }

}