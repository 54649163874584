class ServerConstants {

    static get MAX_MINUTE_INTERVAL() {
        return 15;
    }

    static get MAX_COMMENT_LENGTH() {
        return 150;
    }

}

module.exports = ServerConstants;