import BloomingMenu from 'blooming-menu';
import Swal from 'sweetalert2';
import mergeImages from 'merge-images';

import AuthenticationManager from '../AuthenticationManager';
import Constant from '../Constant';
import LangManager from '../LangManager';
import ProjectController from '../controller/ProjectController';
import AuthController from '../controller/AuthController';

export default class ProjectContent {

    constructor(idElement) {
        this._element = document.getElementById(idElement);
        this._langManager = new LangManager();
    }

    get userId() {
        return window.localStorage.getItem(AuthenticationManager.USERID_KEY);
    }

    render() {
        this.clear();

        const hr = document.createElement('hr');
        this._element.append(hr);

        const h1 = document.createElement('h1');
        h1.innerHTML = this._langManager.getText('PROJECT_TITLE');
        this._element.append(h1);

        const ul = document.createElement('ul');
        this._element.append(ul);

        this.addItem(ul, 'new project');

        ProjectController.getAllProjects(this.userId)
            .then(data => {
                for (const element of data) {
                    this.addItem(ul, element.name, element.locked == '1', element.id, element.image);
                }
            });

        if (AuthenticationManager.userFunctionalities.has("STUDENT")) {
            this.renderStudents();
        }
    }

    renderStudents() {
        ProjectController.getAllStudentsProjects(this.userId)
            .then(data => {

                if (data.length > 0) {
                    const hr = document.createElement('hr');
                    this._element.append(hr);

                    const h1 = document.createElement('h1');
                    h1.innerHTML = this._langManager.getText('PROJECT_STUDENT_TITLE');
                    this._element.append(h1);

                    const ul = document.createElement('ul');
                    this._element.append(ul);

                    for (const element of data) {
                        this.addItem(ul, `${element.user_name} - ${element.name}`, false, element.id, element.image);
                    }
                }
            });
    }

    clear() {
        this._element.innerHTML = '';
    }

    addItem(element, name, locked, projectId = null, imgSrc = null) {

        const li = document.createElement('li');
        const div = document.createElement('div');
        div.classList.add('item');

        const a = document.createElement('a');
        div.appendChild(a);
        const img = document.createElement('img');

        if (imgSrc != null) {
            const imgs = [imgSrc];
            if (locked) imgs.push('./img/lock.png');

            mergeImages(imgs)
                .then(content => img.src = content);

        } else img.setAttribute('src', './img/new-project.png');
        a.appendChild(img);
        a.appendChild(document.createElement('br'));
        const span = document.createElement('span');
        span.innerText = name;
        a.appendChild(span);

        li.appendChild(div);

        if (projectId == null) a.addEventListener('click', (event) => this.newProject_onClick(event), false)
        else a.addEventListener('click', (event) => this.project_onClick(event, projectId, locked), false)

        if (!locked) {
            if (projectId != null) this.applyMenu(li, span, projectId, name);
        }

        element.appendChild(li);
    }

    newProject() {
        this.askName('Create', (name) => {
            ProjectController.createProject(this.userId, name)
                .then(data => {
                    this.render();
                    window.location.href = `${Constant.LAB_URL}?lang=${this._langManager.lang}&projectId=${data.insertId}`;
                });
        })

    }

    newUnregisteredProject() {
        console.log(`${Constant.LAB_URL}?demo=new`);
        window.location.href = `${Constant.LAB_URL}?lang=${this._langManager.lang}&demo=new`;
    }

    newProject_onClick(event) {
        AuthController.me()
            .then(() => {
                this.newProject();
            })
            .catch(() => {
                this.warningUnregistered()
                    .then((event) => {
                        if (event.dismiss === 'cancel') {
                            this.newUnregisteredProject();
                        } else if (event.dismiss !== 'backdrop') {
                            const am = new AuthenticationManager();
                            am.verifyUser()
                                .then(() => {
                                    this.render();
                                    this.newProject();
                                }).catch(() => {
                                    this.newUnregisteredProject();
                                });
                        }
                    })
            })
    }

    project_onClick(event, projectId, locked) {
        if (locked) {

        } else {
            window.location.href = `${Constant.LAB_URL}?lang=${this._langManager.lang}&projectId=${projectId}`;
        }
    }

    applyMenu(element, span, projectId, name) {
        const menu = new BloomingMenu({
            startAngle: 230,
            endAngle: 420,
            itemsNum: 4,
            radius: 75,
            fatherElement: element,
            mainContent: '+'
        });

        menu.render();

        // rename
        menu.props.elements.items[0].addEventListener('click', (event) => {
            this.askName('Rename', (data) => {
                ProjectController.updateProject(projectId, data)
                    .then(() => {
                        this.render();
                    });
            });
        })

        // copy
        menu.props.elements.items[1].addEventListener('click', (event) => {
            this.askName('Duplicate', (data) => {
                ProjectController.duplicateProject(projectId, data)
                    .then((res) => {
                        this.render();
                    });
            });
        })

        // delete
        menu.props.elements.items[2].addEventListener('click', (event) => {
            this.confirmDelete(() => {
                ProjectController.deleteProject(projectId);
                this.render();
            });
        })

        // download
        menu.props.elements.items[3].addEventListener('click', (event) => {
            ProjectController.download(projectId, name);
        })

    }

    askName(text, callback) {
        Swal.fire({
            title: 'Project name',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: text,
            showLoaderOnConfirm: true,
            preConfirm: callback
        })
    }

    warningUnregistered() {
        return Swal.fire({
            title: 'Warning',
            icon: 'warning',
            text: 'Your project will not be saved because you are not registered.',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'register',
            cancelButtonText: 'never mind'
        })
    }

    confirmDelete(callback) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                callback();
            }
        })
    }

}