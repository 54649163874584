let singleton = null;

export default class ParameterManager {

    constructor() {
        if (!singleton) {
            this._url = new URL(window.location.href);
            this._params = new Map();
            this._paramStorages = new Map();

            singleton = this;
        }

        return singleton;
    }

    registerParam(name, defaultValue = null, elementName = name, storage = true, storageName = `blockly-param-${name}`) {
        let value = this._url.searchParams.get(name);

        if (storage) {
            const localValue = localStorage.getItem(storageName);
            value = value ? value : localValue;
        }
        value = value != null ? value : defaultValue;

        this._params.set(name, value);
        if (storage) {
            localStorage.setItem(storageName, value);
            this._paramStorages.set(name, storageName);
        }

        let element = document.getElementsByName(elementName)[0];
        if (element) {
            element.value = value;
        }

        return this;
    }

    clearParam(name) {
        let value = this._url.searchParams.get(name);
        if (value) {
            this._url.searchParams.set(name, '');
            window.history.pushState({}, document.title, this._url.href);
        }

        return this;
    }

    getParam(name) {
        const value = this._params.get(name);
        if (value == 'true') return true;
        else if (value == 'false') return false;
        else if (!isNaN(value)) return Number.parseInt(value);

        return decodeURI(value);
    }

    hasParam(name) {
        return this._url.searchParams.has(name);
    }

    deleteParam(name) {
        this._url.searchParams.delete(name);
        this._params.delete(name);
        return this._params.delete(name);
    }

    setParam(name, value) {
        this._url.searchParams.set(name, value);
        this._params.set(name, value);
        if (this._paramStorages.has(name)) {
            const storageName = this._paramStorages.get(name);
            localStorage.setItem(storageName, value);
        }
        return this;
    }

    applyLocation() {
        window.location = this._url;
        return this;
    }

}