import Swal from 'sweetalert2';
import LangManager from './LangManager';

export default class AboutManager {

    constructor() {
        this._langManager = new LangManager();
    }

    render(register = true) {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: this._langManager.getText('ABOUT_TITLE'),
                html: `
                    <form id="swal-about-form" onSubmit="return false;">
                        <div class="about">
                            ${this._langManager.getText('ABOUT_TEXT')}  
                        </div>
                        <button id="swal-confirm" type="button" style="display: ${register ? 'block' : 'none'}" class="swal2-confirm swal2-styled" style="display: inline-block;" aria-label="">${this._langManager.getText('AUTH_LOGIN_NEW')}</button>
                        <button id="swal-cancel" type="button" style="display: ${register ? 'block' : 'none'}" class="swal2-cancel swal2-styled" style="display: inline-block;" aria-label="">${this._langManager.getText('NEXT_TIME')}</button>
                        <button id="swal-got" type="button" style="display: ${register ? 'none' : 'clock'}" class="swal2-confirm swal2-styled" style="display: inline-block;" aria-label="">${this._langManager.getText('GOT_IT')}</button>
                    </form>
                    `,
                showConfirmButton: false,
                showCancelButton: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    resolve();
                },
                allowOutsideClick: true,
                onOpen: () => {
                    const confirm = document.getElementById('swal-confirm');
                    confirm.addEventListener('click', () => {
                        Swal.clickConfirm();
                    })

                    const cancel = document.getElementById('swal-cancel');
                    cancel.addEventListener('click', () => {
                        Swal.clickCancel();
                    })

                    const got = document.getElementById('swal-got');
                    got.addEventListener('click', () => {
                        Swal.clickConfirm();
                    })

                },
            }).then((result) => {
                if (!result.dismiss) {
                    resolve(result);
                } else {
                    reject(result);
                }
            }).catch(reject)
        });
    }

}