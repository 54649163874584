import axios from 'axios'
import BaseController from './BaseController';

export default class RatingController {

    static create(rating, comment) {
        const query = `/api/rating/`;

        return new Promise((success, error) => {
            axios({
                    method: 'post',
                    url: query,
                    headers: BaseController.headers,
                    data: {
                        rating: rating,
                        comment: comment
                    }
                }).then(response => {
                    console.log(response.data);
                    console.log('Rating sent successfully');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }
}