import axios from 'axios'
import BaseController from './BaseController';

export default class AuthController {

    static login(login, password) {
        const query = `/api/auth/login/`;

        return new Promise((success, error) => {
            axios({
                    method: 'post',
                    url: query,
                    data: {
                        name: login,
                        password: password
                    }
                }).then(response => {
                    console.log('Loggin successful');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

    static register(login, email, password) {
        const query = `/api/auth/register/`;

        return new Promise((success, error) => {
            axios({
                    method: 'post',
                    url: query,
                    data: {
                        name: login,
                        email: email,
                        password: password
                    }
                }).then(response => {
                    console.log('Registration successful');
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

    static me() {
        const query = `/api/auth/me/`;

        return new Promise((success, error) => {
            axios({
                    method: 'get',
                    url: query,
                    headers: BaseController.headers
                }).then(response => {
                    success(response.data);
                })
                .catch((err) => {
                    BaseController.logError(err);
                    error(err);
                });
        });

    }

}